@import url('https://fonts.googleapis.com/css?family=Poppins');

.App {
  text-align: center;

  padding-right: 0 !important;
}
h1{
  font-size: 34px;
}
a{
  text-decoration: none !important;

}
body{
  background-color: #ffffff !important;
  font-family: 'Poppins' !important;
  overflow-x: hidden;
}

.margin-100{
  margin-top: 100px;
    margin-bottom: 100px;
}
.header img {
    width: 100%;
        object-fit: cover;
    /* height: 40vh;
     object-fit: none;

     margin: 100px 0;
    object-position: 100% 80%; */
}
.header img{
  width: 100%;
}

.feeling{
  color: white;
  font-size: 2rem;
  padding-bottom: 5px;
  border-bottom: 3px solid white;
  z-index: 10;
  position: absolute;
  bottom: 5%;
  right: 4%;
}
/*
.feeling {
  border-bottom: 5px solid #fff;
  text-align: left;
  color: #fff;
  font-size: 3.2rem;
  padding-bottom: 10px;
  position: absolute;
  z-index: 10;
}

.feeling {
  border-left: 30px solid #fff;
  border-radius: 50%;
  color: #fff;
  font-size: 2.5rem;
  padding: 40px 55px;
  position: absolute;
  text-align: start;
  z-index: 10;
}
*/
.header{
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
}
.header h1{
  font-family: 'Poppins';
font-style: normal;
font-weight: 900;
text-shadow: 3px 4px 2px #474747
}

.f-w-700{
  font-weight: 700;
}
.navbar .logo{
  height: 120px;
}
.navbar .nav-item a{
  color: #303030;
  /*font-size: 18px;*/
  font-size: 20px;
  text-transform: uppercase;
}
.navbar .nav-item:hover{
  color: black;
}
.navbar{
  z-index: 99;
}
.margin-t-20{
  margin-top: 20px;
}
.home .services .content{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 30px;
}
.home .main-content .services .content .s-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
}
.home .main-content .services .content .s-card img{
    max-height: 100px;
}
.home .main-content .services .content .s-card h4{
    margin-top: 30px;
}
@media only screen and (max-width: 600px) {
  .home .main-content .services .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}



.home .main-content .pillars{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  gap: 30px;
}
.home .main-content .pillars .p-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
}

.home .main-content .pillars .p-card h4{
  margin-top: 20px;
}
.home .main-content .pillars .p-card img{
  max-height: 100px;
}

.padding-30{
  padding: 30px 0;
}

@media only screen and (max-width: 600px) {
  .home .main-content .pillars{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}

.home .main-content .contact{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 50px 0;
  width: 100%;
}
.home .main-content .contact .content{
    width: 100%;
}

.home .main-content .contact .left{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.home .main-content .contact .right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.home .main-content .contact .content.left .icon{
  max-width: 30px;
}
.home .main-content .contact .content.left .info{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.home .main-content .contact .content.left .info a{
  text-decoration: none;
  color: #000000;
}


.home .s-card{
  -webkit-box-shadow: 7px 6px 7px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 7px 6px 7px 0px rgba(0,0,0,0.75);
box-shadow: 7px 6px 7px 0px rgba(0,0,0,0.75);
}
.home .main-content .services .content .s-card img {
    max-height: 400px;
    width: 100%;
}

.home .main-content .services .content .s-card h4 {
width: 100%;
    background-color: #f78648;
    padding: 20px;
}
.home .main-content .services .content .s-card .button{
padding: 0 20px 20px 20px;
}
.home .main-content .services .content .s-card .btn{
background-color: #8b8b8f;
color: white;
}
.home .main-content .services .content .s-card .text{
padding: 10px 10px 10px 10px;
}
.home .main-content .services .content .s-card .title{
color: white;
margin-top: 5px;
}

.home #cert {
  background-color: #f78648;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:white;
  padding: 40px 20px;
}
.home #about {
  background-color: #f78648;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:white;
  padding: 40px 20px;
}
.home #about span {
  max-width: 1200px;
  display:block;
}

.home #why-us .tiles{
  display: flex;
  flex-direction: row;
    flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 16px;
}

.home #why-us .tile img {
    /* max-height: 100px;
    object-fit: scale-down; */
    width: 100%;
    height: 100%;
    max-height: 100px;
}
.home #why-us .tile .image {
    width: 100%;
    height: 100%;
    
}

.home #why-us .tile{
  border-top: 20px solid #f78648;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-weight: 700;
  flex-basis: 30%;
    padding: 40px;
    -webkit-box-shadow: -1px 6px 7px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 6px 7px 0px rgba(0,0,0,0.75);
    box-shadow: -1px 6px 7px 0px rgba(0,0,0,0.75);
}


.home #customers .tiles{
  display: flex;
  flex-direction: row;
    flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.home #customers .tile img{
  max-height: 300px;
      max-width: 300px;
      object-fit: scale-down;
}
.home #customers .tile{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-weight: 700;
  flex-basis: 30%;
  padding: 40px;
}
.home #services .btn:hover{
    background-color: #f78648;
}
.home #why-us .tile:hover{
  transform: scale(1.05);
}
.home #customers .tile:hover{
  transform: scale(1.1);
}

footer{
    background-color: #f78648;
    height: 100%;
    padding: 40px;
    color: white;
    margin-top: 40px;
}
footer a{
  color: white;
}
footer .footer-inner {

    display: flex!important;
    justify-content: space-evenly;
    align-items: flex-start;
}

footer li.nav-item {
    gap: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
footer li.nav-item > i{
  font-size: 1.3rem;
}
footer .footer-contact {
  gap: 10px;
}

footer .footer-contact .nav {
  gap: 10px;
}

footer .footer-navigation{
  gap: 10px;
}
footer .footer-navigation .nav{
  gap: 10px;
}
footer .footer-head {
    text-align: left;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
}
footer li{
  font-size: 16px;
}

footer a:hover{
  color: black;
}
.navigation a:hover{
  color: #f78648;
}
nav.navbar.navigation{
  padding: 30px 0 30px 0!important;
}

.contact > div{
  flex-basis: 50%;
  justify-content: flex-start;
  align-items: flex-start;
}
.contact-text {
  gap: 30px;

}
.address-wrap{
  gap: 100px;
justify-content: flex-start;
align-items: center;
width: 100%;
}
.address-wrap ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
.address-wrap .btn{
  border: 3px solid #f78648;
  color: #f78648;
}
.address-wrap .btn:hover{
  background-color: #f78648;
  color: white;
}
.contact-wrap > div{
  gap: 10px;
}
.contact-wrap > div i{
  font-size: 24px;
}
.contact-wrap{
  gap: 16px;
}
.contact img{
  border-radius: 5px;
}
.contact a{
  color: black;
}

.text-left{
  text-align: left;
}

.references .images img{
  /*width: 50%;*/
  padding: 1px;
}

.gdpr {
  text-align: left;
}
.gdpr strong{
    text-align: center;
    width: 100%;
    display: block;
}

.services .categories{
  gap: 3rem;
  justify-content: center;
  align-items: flex-start;

}
.services .categories a{
  color: black;
}
.services .category{
  background-color: #fff;
  list-style: none;
  position: relative;
  display: flex;
  gap: 16px;
  flex: 1 1 0;
  box-shadow: 7px 7px 10px rgb(25 24 28 / 20%);
  z-index: 0;

}
.references > h3::before {
    content: '';
    position: absolute;
    height: 5px;
    background: #25405b;
    width: 100%;
    left: 50%;
    top: 50%;
    margin-top: -2.5px;
    z-index: -1;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
}
.references > h3 {
    position: relative;
}
.references > h3 > span {
  background: #fff;
    padding: 0 28px;
    position: relative;
    z-index: 3;
}
.services .image > i{
  font-size: 3rem;
}
.services .category .title{
  text-transform: uppercase;
}
.services .category{
  overflow: hidden;
}
.services .category{
  z-index: 1;
  cursor: pointer;
}
.services .category:hover .cover{
  opacity: 1;
    -webkit-transform: translate(0,0)!important;
    -moz-transform: translate(0,0)!important;
    -ms-transform: translate(0,0)!important;
    -o-transform: translate(0,0)!important;
    transform: translate(0,0)!important;
}
.services .category .cover {
    position: absolute;
    background: #f78648;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    pointer-events: none;
    transform: translate(-0%, -100%);
    -webkit-transition: -webkit-transform .4s ease-out,opacity .4s ease-out;
    -moz-transition: -moz-transform .4s ease-out,opacity .4s ease-out;
    transition: transform .4s ease-out,opacity .4s ease-out;
    pointer-events: none;

}
.z-index3{
  z-index: 3;
}

.services .services-container .service > div{
  flex-basis: 50%;
  justify-content: flex-start;
  align-items: flex-start;
}

.services .services-container .service-text {
  gap: 30px;

}



@media only screen and (max-width: 1024px) {
.feeling {
  color: white;
  font-size: 2rem;
  padding-bottom: 7px;
  border-bottom: 5px solid white;
}
}

@media only screen and (max-width: 768px) {
  .home .services .content {
    flex-direction: column;
  }
  .home #why-us .tiles {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .feeling{

      color: white;
      font-size: 1rem;
      padding-bottom: 5px;
      border-bottom: 2px solid white;
    
  }
  .address-wrap ul{
    text-align: center;
  }
  .home .main-content .contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 30px;
  }
  .home .main-content .contact .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home .main-content .contact .right img{
    max-width: 150px;
  }
  .header img {
    /* object-fit: cover; */
    max-height: 250px;
}
.home .main-content .services .content .s-card {
    margin: 20px;
    -webkit-box-shadow: -1px 6px 7px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 6px 7px 0px rgba(0,0,0,0.75);
    box-shadow: -1px 6px 7px 0px rgba(0,0,0,0.75);
}

.home #why-us .tile {
  flex-grow: 1;
  /* flex-basis: 20%; */
  padding: 10px;
  align-self: stretch;
}
.home #why-us .tiles {
    align-items: flex-start;
}
.home #customers .tile img {
    max-height: max-content;
}

footer .footer-navigation .nav {
    gap: 10px;
    justify-content: center;
    align-items: center;
}
footer .footer-head {
    text-align: center;
}
footer .footer-inner {
    align-items: center;
    flex-direction: column;
    gap: 50px;
}
.navbar .logo {
    height: 40px;
}
.navbar{
  padding: 0;
}


.address-wrap {
    gap: 30px;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column !important;
}
.contact {
  flex-direction: column;
  gap: 100px;
}
.contact > div {
  justify-content: center;
      align-items: center;
}

.contacts{
      padding: 50px 0 0 0;
      gap: 100px;
}
.contact.contact-reverse {
    flex-direction: column-reverse;
}

.services-container {
  flex-direction: column;
  gap: 30px;
}
.services-container .service > div {
  justify-content: center;
      align-items: center;
      width: 100%;
      flex-basis: unset !important;
}


.services-container .service{
  width: 100%;
  flex-direction: column !important;
}
.services-container .service-reverse {
    flex-direction: column-reverse !important;
}
.App > .container{
      //padding-top: 100px;
}
.references .reference {
    flex-direction: column-reverse !important;
}
.references .text {
    width: 100% !important;
}
.references .images{
  width: 100% !important;
}
.references .images img{
  width: 100% !important;
}
.reference h3{
  text-align: center;
}
.reference .text .content p{
  text-align: center;
}
.services .category{
  width: 100%;
}
.services .categories{
  flex-direction: column !important;
}
}

@media only screen and (min-width: 2200px) {
  .header img {
    object-fit: cover;
    width: 100%;
  }
  }

  @media only screen and (max-width: 600px) {
  .home .main-content .services .content .s-card img {
    max-height: 200px;
  }}
  